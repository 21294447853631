import MDBox from "components/MDBox";
import {
  Card,
  CardHeader,
  DialogActions,
  DialogContent,
  Icon,
  IconButton,
  Tooltip,
  Chip,
  Dialog,
} from "@mui/material";

import client from "ApiClient";
import { useEffect, useState } from "react";
import moment from "moment";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";

/* eslint-disable */
export default function Invoices(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const { openModal, closeModal } = props;

  const options = {
    method: "GET",
    url: "reports",
  };

  const getInvoices = async () => {
    setIsLoading(true);
    options.url = `stripe/getInvoices`;
    await client
      .request(options)
      .then((response) => {
        console.log(response);
        setInvoices(response);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getInvoices();
  }, []);

  const dataTableData = {
    columns: [
      {
        Header: "Actions",
        accessor: "dom_id",
        Cell: ({ row }) => (
          <Tooltip title="Download" placement="bottom">
            <IconButton
              onClick={() => {
                window.open(row.original.invoice_pdf_url);
              }}
            >
              <Icon>download</Icon>
            </IconButton>
          </Tooltip>
        ),
      },
      {
        Header: "status",
        accessor: "status",
        Cell: ({ value }) => {
          let color = "info";
          switch (value) {
            case "paid":
              color = "success";
              break;
            case "Paused":
              color = "warning";
              break;
            case "fail":
              color = "error";
              break;
          }
          return <Chip style={{ width: "100px", color: "#FFF" }} color={color} label={value} />;
        },
      },
      {
        Header: "amount",
        accessor: "amount",
        Cell: ({ value }) => "$ " + value,
      },
      {
        Header: "date",
        accessor: "date",
        Cell: ({ value }) => moment(value).format("DD/MM/YYYY"),
      },
    ],

    rows: invoices,
  };

  const clearVariables = (reason) => {
    if (reason === "backdropClick") return;
    closeModal();
  };

  return (
    <MDBox pb={3} pt={2}>
      <Card>
        <CardHeader title="Invoices" />
        <DataTable table={dataTableData} entriesPerPage={true} canSearch />
      </Card>
    </MDBox>
  );
}
