import { Button, Icon, Menu, MenuItem } from "@material-ui/core";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import MDBox from "components/MDBox";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { setOpenConfigurator, useMaterialUIController } from "context";

export default function MSAccount() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [controller, dispatch] = useMaterialUIController();
  const { openConfigurator } = controller;
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();

  return (
    <MDBox display="flex" justifyContent="center">
      <Button
        fullWidth
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <ManageAccountsIcon fontSize="small" />
        &nbsp;&nbsp; My account &nbsp;&nbsp;
        <KeyboardArrowUpIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            handleConfiguratorOpen();
          }}
        >
          <Icon fontSize="small">settings</Icon>&nbsp; Settings
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            navigate("/profile");
          }}
        >
          <Icon fontSize="small">account_circle</Icon>&nbsp; My profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.open("https://help.floathosting.com/new-ticket", "_blank");
          }}
        >
          <Icon fontSize="small">confirmation_number</Icon>&nbsp; Submit a ticket
        </MenuItem>

        {JSON.parse(localStorage.getItem("account")).user_free === false && (
          <MenuItem
            onClick={() => {
              handleClose();
              navigate("/billing");
            }}
          >
            <CreditCardIcon fontSize="small" />
            &nbsp; Billing
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            handleClose();
            localStorage.removeItem("AuthorizationToken");
            navigate("/sign-in");
          }}
        >
          <Icon fontSize="small">logout</Icon>&nbsp; Logout
        </MenuItem>
      </Menu>
    </MDBox>
  );
}
