import spinner from "../../../assets/spinner.gif";
import client from "ApiClient";

export const ImageAI = (editor, opts = {}) => {
  const keyCustomCode = "image-ai-plugin__code";
  const typeCustomCode = "image-ai";
  const commandNameCustomCode = "image-ai:open-modal";
  let timedInterval = null;

  const options = {
    blockCustomCode: {},
    propsCustomCode: {},
    toolbarBtnCustomCode: {},
    placeholderScript: `<div style="pointer-events: none; padding: 10px;">
      <svg viewBox="0 0 24 24" style="height: 30px; vertical-align: middle;">
        <path d="M13 14h-2v-4h2m0 8h-2v-2h2M1 21h22L12 2 1 21z"></path>
        </svg>
      Custom code with <i>&lt;script&gt;</i> can't be rendered on the canvas
    </div>`,
    modalTitle: "Write your prompt to generate the image",
    codeViewOptions: {},
    buttonLabel: "Generate image",
    commandCustomCode: {},
    ...opts,
  };

  editor.DomComponents.addType("script", {
    view: {
      onRender() {
        // @ts-ignore
        const { model, el } = this;
        const isCC = model.closestType(typeCustomCode);
        isCC && (el.innerHTML = "");
      },
    },
  });

  editor.DomComponents.addType(typeCustomCode, {
    model: {
      defaults: {
        name: "Image AI",
        editable: true,
        components: {
          tagName: "span",
          components: { type: "textnode", content: "Insert here your prompt" },
        },
        ...options.propsCustomCode,
      },

      /**
       * Initilize the component
       */
      init() {
        // @ts-ignore
        this.on(`change:${keyCustomCode}`, this.onCustomCodeChange);
        const initialCode = this.get(keyCustomCode);
        !this.components().length && this.components(initialCode);
        const toolbar = this.get("toolbar");
        const id = "custom-codee";

        // Add the custom code toolbar button if requested and it's not already in
        // @ts-ignore
        if (options && !toolbar.filter((tlb) => tlb.id === id).length) {
          // @ts-ignore
          toolbar.unshift({
            id,
            command: commandNameCustomCode,
            label: `<svg viewBox="0 0 24 24">
              <path d="M14.6 16.6l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4m-5.2 0L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4z"></path>
            </svg>`,
            ...options,
          });
        }
      },

      /**
       * Callback to launch on keyCustomCode change
       */
      // @ts-ignore
      onCustomCodeChange() {
        // @ts-ignore
        this.components(this.get(keyCustomCode));
      },
    },

    view: {
      events: {
        dblclick: "onActive",
      },

      init() {
        // @ts-ignore
        this.listenTo(this.model.components(), "add remove reset", this.onComponentsChange);
        // @ts-ignore
        this.onComponentsChange();
      },

      /**
       * Things to do once inner components of custom code are changed
       */
      // @ts-ignore
      onComponentsChange() {
        timedInterval && clearInterval(timedInterval);
        timedInterval = setTimeout(() => {
          // @ts-ignore
          const { model, el } = this;
          const content = model.get(keyCustomCode) || "";
          let droppable = true;

          // Avoid rendering codes with scripts
          // if (content.indexOf('<script') >= 0 && opts.placeholderScript) {
          //   el.innerHTML = opts.placeholderScript;
          //   droppable = false;
          // }

          model.set({ droppable });
        }, 0);
      },

      onActive() {
        // @ts-ignore
        const { model, em } = this;
        em.get("Commands").run(commandNameCustomCode, { target: model });
      },
    },
  });

  //LOAD BLOCKS
  editor.BlockManager.add(typeCustomCode, {
    label: "Smart Image",
    media: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M448 80c8.8 0 16 7.2 16 16l0 319.8-5-6.5-136-176c-4.5-5.9-11.6-9.3-19-9.3s-14.4 3.4-19 9.3L202 340.7l-30.5-42.7C167 291.7 159.8 288 152 288s-15 3.7-19.5 10.1l-80 112L48 416.3l0-.3L48 96c0-8.8 7.2-16 16-16l384 0zM64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zm80 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"/></svg>
    `,
    category: process.env.REACT_APP_SITE_TITLE + " AI",
    activate: true,
    select: true,
    content: { type: typeCustomCode },
    ...options,
  });

  //COMMANDS

  const appendToContent = (target, content) => {
    if (content instanceof HTMLElement) {
      target.appendChild(content);
    } else if (content) {
      target.insertAdjacentHTML("beforeend", content);
    }
  };

  // Add the custom code command
  editor.Commands.add(commandNameCustomCode, {
    keyCustomCode,

    run(editor, s, opts = {}) {
      const target = options.target || editor.getSelected();
      // @ts-ignore
      this.target = target;

      if (target?.get("editable")) {
        // @ts-ignore
        this.showCustomCode(target, opts);
      }
    },

    stop(editor) {
      editor.Modal.close();
    },

    /**
     * Method which tells how to show the custom code
     * @param  {Component} target
     */
    showCustomCode(target, options) {
      const title = "Write your promt";
      const code = target.get(keyCustomCode) || "";
      // @ts-ignore
      const content = this.getContent();
      editor.Modal.open({ title, content }).onceClose(() =>
        editor.stopCommand(commandNameCustomCode)
      );
      // @ts-ignore
      this.getCodeViewer().setContent(code);
    },

    /**
     * Custom pre-content. Can be a simple string or an HTMLElement
     */
    getPreContent() {},

    /**
     * Custom post-content. Can be a simple string or an HTMLElement
     */
    getPostContent() {},

    /**
     * Get all the content for the custom code
     * @return {HTMLElement}
     */
    getContent() {
      // @ts-ignore
      const codeViewer = this.getCodeViewer();
      const content = document.createElement("div");
      const pfx = editor.getConfig("stylePrefix");
      content.className = `${pfx}custom-code`;
      // @ts-ignore
      appendToContent(content, this.getPreContent());
      content.appendChild(codeViewer.getElement());
      // @ts-ignore
      appendToContent(content, this.getPostContent());
      // @ts-ignore
      appendToContent(content, this.getContentActions());
      codeViewer.refresh();
      setTimeout(() => codeViewer.focus(), 0);

      return content;
    },

    /**
     * Get the actions content. Can be a simple string or an HTMLElement
     * @return {HTMLElement|String}
     */
    getContentActions() {
      const cont = document.createElement("div");

      const btn = document.createElement("button");
      btn.setAttribute("type", "button");
      const pfx = editor.getConfig("stylePrefix");
      btn.innerHTML = options.buttonLabel;
      btn.className = `${pfx}btn-prim ${pfx}btn-import__custom-code`;
      btn.id = "btnSave";
      // @ts-ignore
      btn.onclick = () => this.handleSave();
      cont.appendChild(btn);

      const img = document.createElement("img");
      img.src = spinner;
      img.alt = "spinner";
      img.id = "spinner";
      img.height = 32;
      img.width = 32;
      img.style.visibility = "hidden";
      img.style.margin = "10px";
      cont.appendChild(img);

      return cont;
    },

    /**
     * Handle the main save task
     */
    handleSave() {
      document.getElementById("spinner").style.visibility = "visible";
      document.getElementById("btnSave").disabled = true;
      // @ts-ignore
      const { target } = this;
      // @ts-ignore
      const code = this.getCodeViewer().getContent();

      const options = {
        method: "GET",
        url: "pages",
        headers: {
          "content-type": "application/json",
        },
      };

      options.method = "GET";
      options.url = `grapeai/generateImage?prompt=${code}`;

      //const el = this.view.el; // target your div
      //el.src = spinner;
      client
        .request(options)
        .then((response) => {
          target.set(keyCustomCode, `<img src="${response}" alt="image" />`);
          const assetManager = editor.AssetManager;        
          assetManager.add(response);
          assetManager.render();
          document.getElementById("spinner").style.visibility = "hidden";
          document.getElementById("btnSave").disabled = false;
          editor.Modal.close();
        })
        .catch((error) => {
          document.getElementById("spinner").style.visibility = "hidden";
          document.getElementById("btnSave").disabled = false;
          console.log(error);
        });
      /*
      client
        .request(options)
        .then((response) => {
          target.set(keyCustomCode, response);
          editor.Modal.close();
          //  el.src = response;
        })
        .catch((error) => {
          
          console.log(error);
        });
        */
    },

    /**
     * Return the code viewer instance
     * @return {CodeViewer}
     */
    getCodeViewer() {
      // @ts-ignore
      if (!this.codeViewer) {
        // @ts-ignore
        this.codeViewer = editor.CodeManager.createViewer({
          codeName: "htmlmixed",
          theme: "hopscotch",
          readOnly: 0,
          ...options.codeViewOptions,
        });
      }
      // @ts-ignore
      return this.codeViewer;
    },

    ...options.commandCustomCode,
  });
};
