export default (editor, options) => {
  new DragDropForGrapesJs(editor, options);
}

class DragDropForGrapesJs {

  constructor(
    editor,
    {
      dragDefault = [],
      dragPlace = {default:1},
      dragClasses = ['gjs-selected','gjs-freezed','animation'],
      animation = 'none',
      customAnimation = ''
    } = {}
  ) {
    this._DragDropGrapesJsData = {
      editor: editor,
      frame: null,
      placeHolder: null,
      refBlock: null,
      insertMethod: null,
      hideGrapesPlaceHolderStyle: null,
      dragged: null,
      appendBlock: null,
      dragDefault: dragDefault,
      dragPlace: dragPlace,
      dragClasses: dragClasses,
      animation: animation,
      customAnimation: customAnimation
    };
    if(!this.customAnimation){
    switch (this.animation) {
      case 'none':
        break;
      case 'animation1':
        editor.Config.canvasCss = `     
        @keyframes slidein {
          from {
            max-height: 0px;
            transform: scale(0);
            opacity: 0;
          }
        
          to {
            max-height: 1000px;
            transform: scale(1);
            opacity: 1;
          }
        }
    
        /* animate new box */
        .animation {
          animation-duration:0.5s;
          animation-name: slidein;
        }`;
        break;
      case 'animation2':
        editor.Config.canvasCss = `     
        @keyframes drop-in {
          from {
            transform:
              rotate(-30deg) translateY(-100%);
            opacity: 0;
          }
          to {
            transform:
              rotate(0deg) translateY(0%);
            opacity: 1;
          }
        }
    
        /* animate new box */
        .animation {
          animation: drop-in 1000ms;
        }`;
        break;
      case 'animation3':
        editor.Config.canvasCss = `     
        @keyframes slide-in {
          from {
            transform: translateX(-100%);
            opacity: 0.25;
          }
          to {
            transform: translateX(0%);
            opacity: 1;
          }
        }
    
        /* animate new box */
        .animation {
          animation: slide-in 1000ms;
          animation-iteration-count: 1;
        }`;
        break;
      case 'animation4':
        editor.Config.canvasCss = `     
        @keyframes wipe-enter {
          0% {
            transform: scale(0, .025);
          }
          50% {
            transform: scale(1, .025);
          }
        }
    
        /* animate new box */
        .animation {
          animation: wipe-enter 1s 1;
        }`;
        break;
      case 'animation5':
        editor.Config.canvasCss = `     
        @keyframes pulse {
          0% {
            transform: scale(1);
          }
        
          50% {
            transform: scale(1.1);
          }
        
          100% {
            transform: scale(1);
          }
        }
                
    
        /* animate new box */
        .animation {
          animation: pulse 1s 1;
        }`;
        break;
    }
    }else{
      editor.Config.canvasCss = this.customAnimation;
    }

    editor.on('frame:load', ({el, model, view}) => {
        this.frame = el;
      }
    );

    editor.on('block:drag:start', (block, place) => {
      this.appendBlock = editor.DomComponents.addComponent(block.get('content'));
      let el;
      if (Array.isArray(this.appendBlock)) {
        el = createHtmlElem(
          'div',
          null,
          {}
        );
        this.appendBlock.forEach(
          blockElement => {
            const blockEl = blockElement.getEl();
            el.appendChild(blockEl.cloneNode(true));
            this.onDragRemove(blockEl);
          }
        );
       } else {
         el = this.appendBlock.getEl();
       }
      this.placeHolder = el.cloneNode(true);
      this.onDragStart(el);
      this.onDragRemove(el);
    });

    editor.on('sorter:drag:start', (el) => {
      this.dragged = el;
      this.onDragStart(el);
    });

    editor.on('sorter:drag', (block) => {
      if (block.targetModel == undefined) { this.onDragEnd(block.sourceModel); }
      if (!block.targetModel) return;
      let placeHolder = this.placeHolder || this.dragged;
      if (!placeHolder) {
        const el = editor.addComponents(
          editor.getModel().get('dragContent')
        )[0].getEl();
        placeHolder = this.placeHolder = el.cloneNode(true);
        this.onDragStart(el);
        this.onDragRemove(el);
      }
      if (this.isDragDefault(placeHolder)) {
        return false;
      } else {
        if (!block.dims.length) {
          block.target.appendChild(placeHolder);
        } else if (
          (
            this.refBlock !== block.dims[block.pos.index].el ||
            this.insertMethod !== block.pos.method
          ) &&
          placeHolder !== block.dims[block.pos.index].el
        ) {
          this.refBlock = block.dims[block.pos.index].el;
          placeHolder.classList.add(...this.dragClasses);
          this.insertMethod = block.pos.method;
          if (block.pos.method === 'before') {
            this.refBlock.parentNode.insertBefore(
              placeHolder,
              this.refBlock
            )
          } else {
            this.refBlock.after(placeHolder)
          }
        }
      }
      return true;
    });

    editor.on('sorter:drag:end', this.onDragEnd.bind(this));

    editor.on('block:drag:stop', this.onDragEnd.bind(this));

  }

  get frame() {
    return this._DragDropGrapesJsData.frame;
  }

  set frame(value) {
    this._DragDropGrapesJsData.frame = value;
  }

  get frameContentWindow() {
    return this.frame && this.frame.contentWindow;
  }

  get inFrameData() {
    return this.frameContentWindow && this.frameContentWindow.grapesjsCkeditorData;
  }

  get frameDoc() {
    return this.frame && this.frame.contentDocument;
  }

  get frameBody() {
    return this.frameDoc && this.frameDoc.body;
  }

  get editor() {
    return this._DragDropGrapesJsData.editor
  }

  get placeHolder() {
    return this._DragDropGrapesJsData.placeHolder;
  }

  set placeHolder(value) {
    this._DragDropGrapesJsData.placeHolder = value;
  }

  get appendBlock() {
    return this._DragDropGrapesJsData.appendBlock;
  }

  set appendBlock(value) {
    this._DragDropGrapesJsData.appendBlock = value;
  }

  get refBlock() {
    return this._DragDropGrapesJsData.refBlock;
  }

  set refBlock(value) {
    this._DragDropGrapesJsData.refBlock = value;
  }

  get insertMethod() {
    return this._DragDropGrapesJsData.insertMethod;
  }

  set insertMethod(value) {
    this._DragDropGrapesJsData.insertMethod = value;
  }

  get hideGrapesPlaceHolderStyle() {
    return this._DragDropGrapesJsData.hideGrapesPlaceHolderStyle;
  }

  set hideGrapesPlaceHolderStyle(value) {
    this._DragDropGrapesJsData.hideGrapesPlaceHolderStyle = value;
  }

  get dragged() {
    return this._DragDropGrapesJsData.dragged;
  }

  set dragged(value) {
    this._DragDropGrapesJsData.dragged = value;
  }

  get dragDefault() {
    return this._DragDropGrapesJsData.dragDefault;
  }

  get dragPlace(){
    return this._DragDropGrapesJsData.dragPlace;
  }

  get dragClasses(){
    return this._DragDropGrapesJsData.dragClasses;
  }

  get animation(){
    return this._DragDropGrapesJsData.animation;
  }

  get customAnimation(){
    return this._DragDropGrapesJsData.customAnimation;
  }
  
  isDragDefault(el) {
    if(el){
      return this.dragDefault.includes(el.dataset.gjsType);
    }else{
      return true;
    }
  }

  onDragRemove(el){
    el.remove();
  }

  onDragStart(el) {
     this.insertMethod = null;
     this.refBlock = null;
     this.isDragDefault(el) || this.hideGrapesPlaceHolder();
     const type_block = this.isDragDefault(el);

     if(this.dragPlace.default == 1){
      this.placeHolder && this.placeHolder.classList.add(...this.dragClasses);
     }else{
      if(this.dragPlace.html && type_block != true){
        el = createHtmlElem(
          this.dragPlace.html.block,
          null,
          {innerHTML: this.dragPlace.html.content}
        );
        if(el && this.dragPlace.css){
          el.style = this.dragPlace.css;
        }
      }

      if(this.dragPlace.html  && type_block != true){
        this.placeHolder = el.cloneNode(true);
      }

     }
  }

  onDragEnd(block) {
    this.dragged = null;
    this.placeHolder && this.onDragRemove(this.placeHolder);
    this.hideGrapesPlaceHolderStyle && this.onDragRemove(this.hideGrapesPlaceHolderStyle);
    this.placeHolder = null;
    this.hideGrapesPlaceHolderStyle = null;
    this.appendBlock && (
      Array.isArray(this.appendBlock) ?
      this.appendBlock.forEach(blockElement => this.onDragRemove(blockElement)) :
        this.onDragRemove(this.appendBlock)
    );
    this.appendBlock = null;
  }

  hideGrapesPlaceHolder() {
    if (!this.hideGrapesPlaceHolderStyle) {
      this.hideGrapesPlaceHolderStyle = createHtmlElem(
        'style',
        document.body,
        {
          innerHTML: '.gjs-placeholder {opacity: 0;}'
        }
      );
    }
  }
}


/**
 *
 * @param {string} type
 * @param {HTMLElement} container
 * @param {Object} properties
 * @return {HTMLElement}
 */
function createHtmlElem(type, container, properties) {
  let elem = document.createElement(type);
  setElementProperty(elem, properties);
  container && container.appendChild(elem);
  return (elem);
}

/**
 *
 * @param {Object} elem
 * @param {Object} properties
 */
function setElementProperty(elem, properties) {
  if (properties) {
    for (let key in properties) {
      if (typeof properties[key] === 'object') {
        setElementProperty(elem[key], properties[key]);
      } else {
        elem[key] = properties[key];
      }
    }
  }
}
