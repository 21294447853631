const alignment = (editor) => {
  let activeAlignment = "left"; // Default active alignment

  const setActiveAlignmentButtons = (alignment) => {
    const alignButtons = document.querySelectorAll(".align-button");

    alignButtons.forEach((button) => {
      const buttonAlignment = button.getAttribute("data-align");

      if (buttonAlignment === alignment) {
        button.classList.add("active");
      } else {
        button.classList.remove("active");
      }
    });
  };

  editor.StyleManager.addType("my-custom-prop", {
    create({ change }) {
      const el = document.createElement("div");
      el.innerHTML = `
                <button class="align-button" data-align="left">Left</button>
                <button class="align-button" data-align="center">Center</button>
                <button class="align-button" data-align="right">Right</button>
            `;

      const alignButtons = el.querySelectorAll(".align-button");

      alignButtons.forEach((button) => {
        const alignment = button.getAttribute("data-align");

        button.addEventListener("click", () => {
          alignButtons.forEach((btn) => {
            btn.classList.remove("active");
          });

          button.classList.add("active");

          activeAlignment = alignment; // Update active alignment

          const selectedComponent = editor.getSelected();

          if (selectedComponent) {
            const styles = selectedComponent.getStyle();

            styles["margin-left"] = alignment === "left" ? "0" : "auto";
            styles["margin-right"] = alignment === "right" ? "0" : "auto";
            styles["text-align"] = "center";
            styles["display"] = "block";
            styles["float"] = alignment === "center" ? "none" : alignment;

            if (alignment === "center") {
              styles["float"] = "none";
              styles["margin-left"] = "auto";
              styles["margin-right"] = "auto";
            } else {
              styles["float"] = alignment;
            }

            selectedComponent.setStyle(styles);
          }
        });

        // Set the initial active button based on the active alignment
        const selectedComponent = editor.getSelected();
        if (!selectedComponent) {
          if (alignment === "left") {
            button.classList.add("active");
          }
        }
      });

      return el;
    },
    emit({ updateStyle }, { event }) {
      // No need to emit changes for this simplified example
    },
    update({ el }) {
      // No need to update the UI for this simplified example
    },
    destroy() {
      // Clean up if needed
    },
  });

  editor.on("component:selected", ({ model }) => {
    const selectedComponent = editor.getSelected();
    if (selectedComponent) {
      const styles = selectedComponent.getStyle();
      const alignment = styles["float"] || "left";
      setActiveAlignmentButtons(alignment);
    } else {
      setActiveAlignmentButtons(activeAlignment);
    }
  });
};

export default alignment;
