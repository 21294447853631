export default function replaceTextViewComponent(editor) {
    const domc = editor.DomComponents;

    editor.on('component:selected', () => {
        const openSmBtn = editor.Panels.getButton('views', 'open-sm');
        openSmBtn.set('active', 1);
    });

    const viewEvents = {
        click: function(event) {
            // Immediate selection logic
            const editor = this.model.collection?.parent?.view?.editor;
            if (editor) {
                if (!editor.getSelected() || editor.getSelected().cid !== this.model.cid) {
                    editor.select(this.model);
                    editor.trigger('component:toggled');
                }
            }

            // Delayed TinyMCE activation
            setTimeout(() => {
                if (typeof this.onActive === 'function') {
                    this.onActive(event);
                }
            }, 10);  // TinyMCE activation after a short delay
        },
        input: 'onInput'
    };

    

    domc.addType('text', {
        extend: 'text',
        extendView: 'text',
        view: {
            events: viewEvents
        }
    });

    domc.addType('link', {
        extend: 'link',
        extendView: 'link',

        view: {
            events: viewEvents
        }
    });

    domc.addType('label', {
        extend: 'label',
        extendView: 'label',

        view: {
            events: viewEvents
        }
    });

    domc.addType("accordion-container", {
        extend: 'accordion-container',
        extendView: 'accordion-container',

        view: {
            events: viewEvents
        }

    });

    domc.addType("accordion-content", {
        extend: 'accordion-content',
        extendView: 'accordion-content',

        view: {
            events: viewEvents
        }

    });

    domc.addType("tooltip", {
        extend: 'tooltip',
        extendView: 'tooltip',

        view: {
            events: viewEvents
        }

    });

    domc.addType("typed", {
        extend: 'typed',
        extendView: 'typed',

        view: {
            events: viewEvents
        }

    });   

    // domc.addType('form', {
    //     model: {
    //         // The `defaults` property is handled differently
    //         // and will be merged with the old `defaults`
    //         defaults: {
    //             droppable: false,
    //             style: {
    //                 display: "flex",
    //                 "flex-direction": "column",
    //                 "padding": "50px 50px 50px 50px",
    //                 border: "1px solid #6c7978",

    //                 "background-color": "#f5f5f5",
    //                 "box-sizing": "border-box", "width": "100%", "max-width": "1140px", margin: "12px auto"
    //             }
    //         }

    //     },
    // })
    
    domc.addType('image', {
        model: {
            defaults: {
                src: `<svg class="image-pl-svg" fill="#00a7ff" color="" background="transparent" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><g transform="rotate(0.35655999183654785 5.992621421813402 6.009378433227507)" stroke="null"><path d="m8.797 7.706l-1.469-1.825c-0.049-0.062-0.124-0.1-0.209-0.1 -0.084 0-0.158 0.039-0.207 0.1l-0.196 0.244c-0.009 0.011-0.016 0.022-0.026 0.032 -0.048 0.051-0.114 0.081-0.187 0.081 -0.07 0-0.133-0.028-0.179-0.073 -0.015-0.016-0.03-0.032-0.041-0.052l-0.723-0.897c-0.065-0.08-0.163-0.131-0.272-0.131s-0.207 0.051-0.273 0.131l-1.957 2.428c-0.024 0.038-0.038 0.083-0.038 0.132 0 0.133 0.105 0.241 0.235 0.241l0 0 5.414 0c0.099 0 0.179-0.082 0.179-0.184 0-0.039-0.011-0.075-0.032-0.103l-0.02-0.024 0 0.001z" class="a"></path><path d="m8.378 4.002c-0.324 0-0.587 0.269-0.587 0.601s0.263 0.601 0.587 0.601c0.325 0 0.587-0.269 0.587-0.601s-0.262-0.601-0.587-0.601z" class="a"></path></g></svg>`,
                fallback: `<svg class="image-pl-svg" fill="#00a7ff" color="" background="transparent" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><g transform="rotate(0.35655999183654785 5.992621421813402 6.009378433227507)" stroke="null"><path d="m8.797 7.706l-1.469-1.825c-0.049-0.062-0.124-0.1-0.209-0.1 -0.084 0-0.158 0.039-0.207 0.1l-0.196 0.244c-0.009 0.011-0.016 0.022-0.026 0.032 -0.048 0.051-0.114 0.081-0.187 0.081 -0.07 0-0.133-0.028-0.179-0.073 -0.015-0.016-0.03-0.032-0.041-0.052l-0.723-0.897c-0.065-0.08-0.163-0.131-0.272-0.131s-0.207 0.051-0.273 0.131l-1.957 2.428c-0.024 0.038-0.038 0.083-0.038 0.132 0 0.133 0.105 0.241 0.235 0.241l0 0 5.414 0c0.099 0 0.179-0.082 0.179-0.184 0-0.039-0.011-0.075-0.032-0.103l-0.02-0.024 0 0.001z" class="a"></path><path d="m8.378 4.002c-0.324 0-0.587 0.269-0.587 0.601s0.263 0.601 0.587 0.601c0.325 0 0.587-0.269 0.587-0.601s-0.262-0.601-0.587-0.601z" class="a"></path></g></svg>`,
            },
        },

    });

    domc.addType('sendpad-form', {
        model: {
            defaults: {
                style: {
                    "max-width": "1140px",
                    margin: "0 auto",
                    width: '100%'
                }
            }
        }
    });

    domc.addType("default", {
        model: {
            defaults: {
                style: {
                }
            }
        }
    })

    const blockManager = editor.Blocks;
    blockManager.get('text').get('content').style = { "max-width": "1140px", margin: "0 auto", padding: "10px" }
    blockManager.get('map').get('content').style = { "max-width": "1140px", margin: "0 auto", height: "350px",width:"100%",display:"block" }
    blockManager.get('link').get('content').style = { "max-width": "1140px", margin: "0 auto", width: "100%", display: "block", color: "#d983a6" }
    // blockManager.get('linkk').get('content').style = { "max-width": "1140px", margin: "0 auto", padding: "10px", display: 'inline-block' }

        // domc.addType("linkk", {
        //     model: {
        //         defaults: {
        //             style: {
        //                 "max-width": "1140px",
        //                 margin: "0 auto"
        //             }
        //         }
        //     }
        // })

    // blockManager.get('linkk').get('content').style = { "max-width": "1140px", margin: "0 auto", padding: "10px", display: 'inline-block' }

    domc.addType("custom-code", {
        model: {
            defaults: {
                style: {
                    "max-width": "1140px",
                    margin: "0 auto"
                }
            }
        }
    })

    domc.addType('form', {
        model: {
            defaults: {
                icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 5.5c0-.3-.5-.5-1.3-.5H3.4c-.8 0-1.3.2-1.3.5v3c0 .3.5.5 1.3.5h17.4c.8 0 1.3-.2 1.3-.5v-3zM21 8H3V6h18v2zM22 10.5c0-.3-.5-.5-1.3-.5H3.4c-.8 0-1.3.2-1.3.5v3c0 .3.5.5 1.3.5h17.4c.8 0 1.3-.2 1.3-.5v-3zM21 13H3v-2h18v2z"></path><rect width="10" height="3" x="2" y="15" rx=".5"></rect></svg>',
                style: {
                    "max-width": "1140px",
                    margin: "0 auto"
                }
            }
        }
    })

    // domc.addType('row-1', {
    //     model: {
    //       defaults: {
    //         highlightable: false,
    //         icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 14c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2v-4c0-1.1.9-2 2-2h16c1.1 0 2 .9 2 2v4M4 14h4v-4H4v4m6 0h4v-4h-4v4m6 0h4v-4h-4v4z"></path></svg>`,
    //         traits:[
    //           ...domc.getType('default').model.prototype.defaults.traits,
    //           ...[
    //           ]
    //         ]
    //       },
    //     }
    // });

    // domc.addType('grid-item', {
    // model: {
    //     center: false,
    //     defaults: {
    //     'custom-name': 'Column',
    //     icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8 2h8c1.1 0 2 .9 2 2v16c0 1.1-.9 2-2 2H8c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2m0 8v4h8v-4H8m0 6v4h8v-4H8M8 4v4h8V4H8z"></path></svg>`,
    //     traits:[
    //         ...domc.getType('default').model.prototype.defaults.traits,
    //         ...[
    //         ]
    //     ]
    //     },
    // },
    // });

    // domc.addType('row/3-7', {
    //     model: {
    //       defaults: {
    //         highlightable: false,
    //         icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 14c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2v-4c0-1.1.9-2 2-2h16c1.1 0 2 .9 2 2v4M4 14h4v-4H4v4m6 0h4v-4h-4v4m6 0h4v-4h-4v4z"></path></svg>`,
    //         traits:[
    //           ...domc.getType('default').model.prototype.defaults.traits,
    //           ...[
    //           ]
    //         ]
    //       },
    //     }
    //   });

    // domc.addType('grid-row', {
    //     model: {
    //       defaults: {
    //         'custom-name': 'Grid',
    //         // 'stylable-require': ['direction', 'flex-wrap', 'vertical-align'],
    //         highlightable: false,
    
    //         icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 14c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2v-4c0-1.1.9-2 2-2h16c1.1 0 2 .9 2 2v4M4 14h4v-4H4v4m6 0h4v-4h-4v4m6 0h4v-4h-4v4z"></path></svg>`,
    //         traits:[
    //           ...domc.getType('default').model.prototype.defaults.traits,
    //           ...[
    //             // {
    //             //   full: 1,
    //             //   name: 'Column',
    //             //   labelButton: 'Add Column',
    //             //   type: 'button',
    //             //   command: (editor, trait) => {
    //             //     editor.getSelected().append(grid_row_content)
    //             //   }
    //             // }
    //           ]
    //         ]
    //       },
    //     }
    // });

    //   });

    // domc.addType('grid-row', {
    //     model: {
    //       defaults: {
    //         'custom-name': 'Grid',
    //         // 'stylable-require': ['direction', 'flex-wrap', 'vertical-align'],
    //         highlightable: false,
    //         icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 14c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2v-4c0-1.1.9-2 2-2h16c1.1 0 2 .9 2 2v4M4 14h4v-4H4v4m6 0h4v-4h-4v4m6 0h4v-4h-4v4z"></path></svg>`,
    //         traits:[
    //           ...domc.getType('default').model.prototype.defaults.traits,
    //           ...[
    //             // {
    //             //   full: 1,
    //             //   name: 'Column',
    //             //   labelButton: 'Add Column',
    //             //   type: 'button',
    //             //   command: (editor, trait) => {
    //             //     editor.getSelected().append(grid_row_content)
    //             //   }
    //             // }
    //           ]
    //         ]
    //       },
    //     }
    // });
    //     extend: 'image',
    //     model: {
    //         defaults: {
    //             src: `<svg class="image-pl-svg" fill="#00a7ff" color="" background xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><g transform="rotate(0.35655999183654785 5.992621421813402 6.009378433227507)" stroke="null"><path d="m8.797 7.706l-1.469-1.825c-0.049-0.062-0.124-0.1-0.209-0.1 -0.084 0-0.158 0.039-0.207 0.1l-0.196 0.244c-0.009 0.011-0.016 0.022-0.026 0.032 -0.048 0.051-0.114 0.081-0.187 0.081 -0.07 0-0.133-0.028-0.179-0.073 -0.015-0.016-0.03-0.032-0.041-0.052l-0.723-0.897c-0.065-0.08-0.163-0.131-0.272-0.131s-0.207 0.051-0.273 0.131l-1.957 2.428c-0.024 0.038-0.038 0.083-0.038 0.132 0 0.133 0.105 0.241 0.235 0.241l0 0 5.414 0c0.099 0 0.179-0.082 0.179-0.184 0-0.039-0.011-0.075-0.032-0.103l-0.02-0.024 0 0.001z" class="a"></path><path d="m8.378 4.002c-0.324 0-0.587 0.269-0.587 0.601s0.263 0.601 0.587 0.601c0.325 0 0.587-0.269 0.587-0.601s-0.262-0.601-0.587-0.601z" class="a"></path></g></svg>`,
    //             fallback: `<svg class="image-pl-svg" fill="#00a7ff"  xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><g transform="rotate(0.35655999183654785 5.992621421813402 6.009378433227507)" stroke="null"><path d="m8.797 7.706l-1.469-1.825c-0.049-0.062-0.124-0.1-0.209-0.1 -0.084 0-0.158 0.039-0.207 0.1l-0.196 0.244c-0.009 0.011-0.016 0.022-0.026 0.032 -0.048 0.051-0.114 0.081-0.187 0.081 -0.07 0-0.133-0.028-0.179-0.073 -0.015-0.016-0.03-0.032-0.041-0.052l-0.723-0.897c-0.065-0.08-0.163-0.131-0.272-0.131s-0.207 0.051-0.273 0.131l-1.957 2.428c-0.024 0.038-0.038 0.083-0.038 0.132 0 0.133 0.105 0.241 0.235 0.241l0 0 5.414 0c0.099 0 0.179-0.082 0.179-0.184 0-0.039-0.011-0.075-0.032-0.103l-0.02-0.024 0 0.001z" class="a"></path><path d="m8.378 4.002c-0.324 0-0.587 0.269-0.587 0.601s0.263 0.601 0.587 0.601c0.325 0 0.587-0.269 0.587-0.601s-0.262-0.601-0.587-0.601z" class="a"></path></g></svg>`,
    //         },
    //     },
    //     extendView: 'image',
    //     view: {
    //         events: { ...editor.DomComponents.getType("image").view.getEvents(), "click": "onSingleClick" },
    //         onSingleClick(e) {
    //             editor.select(editor.DomComponents.getComponent(e.target.id))
    //             setTimeout(function () {
    //                 const selectedComponent = editor.getSelected();
    //                 const editorElementIframe = editor.Canvas.getDocument()
    //                 const iframeDocument = editorElementIframe;
    //                 const el = selectedComponent.getEl()

    //                 // hideStyleSector(editor)
    //                 if (iframeDocument) {
    //                     const container = iframeDocument.querySelector(".gjs-higlight-backdrop-container")

    //                     container?.remove()
    //                     const body = iframeDocument.body
    //                     const firstChild = body.firstChild

    //                     if (el.nodeName === "IMG") {
    //                         const backDropDiv = iframeDocument.createElement('div')
    //                         backDropDiv.classList.add("gjs-higlight-backdrop-container")
    //                         backDropDiv.style = `height:${iframeDocument.body.clientHeight}px`
    //                         body.insertBefore(backDropDiv, firstChild)
    //                         setTimeout(() => {
    //                             if (backDropDiv) {
    //                                 backDropDiv.classList.add("active")
    //                             }
    //                         }, 0)

    //                         backDropDiv.addEventListener('click', () => {
    //                             editor.select(null);
    //                             backDropDiv.classList.remove("active");
    //                             setTimeout(() => {
    //                                 if (backDropDiv) {
    //                                     backDropDiv.remove()

    //                                 }
    //                             }, 300)

    //                         })
    //                     }


    //                 }
    //             },5)
    //         }
    //     }
    // });

    // domc.addType('custom-image', {
    //     model: {
    //         defaults: {
    //             // Set tagName to 'div' to create a wrapper div
    //             tagName: 'div',
    //             // Set a class for the wrapper div if needed
    //             classes: ['image-wrapper'],
    //             // Add the default image component as a child
    //             components: [
    //                 {
    //                     type: 'image', // Use the default image component
    //                     tagName: 'img',

    //                     void: true,
    //                     editable: true,
    //                     droppable: false,
    //                     traits: ['alt'], // Add traits if needed
    //                 }
    //             ],
    //         },
    //     },
    //     view: {
    //         // Style the wrapper div if needed
    //         style: {
    //             "min-height": "148px",

    //             "justify-content": "center",
    //             width: "100%",
    //             display: 'flex', // Example: display as inline-block
    //             // Add more styles as needed
    //         },
    //     }
    // });

    // domc.addType('row-1', {
    //     model: {
    //       defaults: {
    //         highlightable: false,
    //         icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 14c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2v-4c0-1.1.9-2 2-2h16c1.1 0 2 .9 2 2v4M4 14h4v-4H4v4m6 0h4v-4h-4v4m6 0h4v-4h-4v4z"></path></svg>`,
    //         traits:[
    //           ...domc.getType('default').model.prototype.defaults.traits,
    //           ...[
    //           ]
    //         ]
    //       },
    //     }
    // });

    domc.addType('grid-item', {
    model: {
        center: false,
        defaults: {
        'custom-name': 'Column',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8 2h8c1.1 0 2 .9 2 2v16c0 1.1-.9 2-2 2H8c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2m0 8v4h8v-4H8m0 6v4h8v-4H8M8 4v4h8V4H8z"></path></svg>`,
        traits:[
            ...domc.getType('default').model.prototype.defaults.traits,
            ...[
            ]
        ]
        },
    },
    });

    domc.addType('row/3-7', {
        model: {
          defaults: {
            highlightable: false,
            icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 14c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2v-4c0-1.1.9-2 2-2h16c1.1 0 2 .9 2 2v4M4 14h4v-4H4v4m6 0h4v-4h-4v4m6 0h4v-4h-4v4z"></path></svg>`,
            traits:[
              ...domc.getType('default').model.prototype.defaults.traits,
              ...[
              ]
            ]
          },
        }
      });

    domc.addType('grid-row', {
        model: {
          defaults: {
            'custom-name': 'Grid',
            // 'stylable-require': ['direction', 'flex-wrap', 'vertical-align'],
            // highlightable: false,
            icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 14c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2v-4c0-1.1.9-2 2-2h16c1.1 0 2 .9 2 2v4M4 14h4v-4H4v4m6 0h4v-4h-4v4m6 0h4v-4h-4v4z"></path></svg>`,
            traits:[
              ...domc.getType('default').model.prototype.defaults.traits,
              ...[
                // {
                //   full: 1,
                //   name: 'Column',
                //   labelButton: 'Add Column',
                //   type: 'button',
                //   command: (editor, trait) => {
                //     editor.getSelected().append(grid_row_content)
                //   }
                // }
            
              ]
            ]
          },
        }
    });


    function generateUniqueId() {
        return 'cmp-' + Date.now();  // Simple timestamp-based ID generator
    }

    editor.on('component:add', component => {
        const componentTypes = ['row-1', 'grid-row', 'grid-item'];
        const componentType = component.get('type');
    
        if (componentTypes.includes(componentType)) {
            const generatedId = generateUniqueId();
            const existingClasses = component.getAttributes().class || '';
            component.addAttributes({ class: `${existingClasses} ${generatedId}`.trim() });
            console.log(`New component added with ID ${generatedId}`);
        }
    });
    
    
}