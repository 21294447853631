import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import client from "ApiClient";

import { useEffect, useState } from "react";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import {
  Card,
  CardHeader,
  CircularProgress,
  Icon,
  Tooltip,
  IconButton,
  Divider,
} from "@material-ui/core";
//import csc from "country-state-city";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CopyToClipboard from "react-copy-to-clipboard";
import SimpleReactValidator from "simple-react-validator";
import { useRef } from "react";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";

/* eslint-disable */
export default function Profile() {
  const [isLoading, setIsLoading] = useState(false);
  const [subdomainTaken, setSubdomainTaken] = useState(false);
  const [openSaveSB, setOpenSaveSB] = useState(false);
  const closeSaveSB = () => setOpenSaveSB(false);
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [companyCountry, setCompanyCountry] = useState("");
  const [companyState, setCompanyState] = useState("");
  const [companyCity, setCompanyCity] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyZip, setCompanyZip] = useState("");
  const [phone, setPhone] = useState("");
  const [subdomain, setSubdomain] = useState("");
  const [account, setAccount] = useState(null);
  const [, forceUpdate] = useState();
  const simpleValidator = useRef(new SimpleReactValidator());
  const options = {
    method: "GET",
    url: "users",
  };

  const verifySubdomain = async () => {
    if (account?.user_subDomain == subdomain) {
      setSubdomainTaken(false);
      updateProfile();
      return;
    }
    options.url = `users/subdomain?subdomain=${subdomain}`;
    await client
      .request(options)
      .then((response) => {
        setSubdomainTaken(!response);
        if (response) {
          updateProfile();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateProfile = () => {
    if (simpleValidator.current.allValid() && !subdomainTaken) {
      setIsLoading(true);
      const options = {
        method: "PUT",
        url: `users/profile`,
        headers: {
          "content-type": "application/json",
        },
      };

      options.data = {
        user_first: first,
        user_last: last,
        user_country: companyCountry,
        user_state: companyState,
        user_city: companyCity,
        user_address: companyAddress,
        user_zip: companyZip,
        user_phone: phone,
        user_subdomain: subdomain,
      };

      client
        .request(options)
        .then((response) => {
          setIsLoading(false);
          setOpenSaveSB(true);
          localStorage.setItem("profileCompleted", true);          
          setAccount(response);
          localStorage.setItem("account", JSON.stringify(response));
        })
        .catch(() => {
          // setErrorSB(true);
        });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  useEffect(() => {
    const pAccount = JSON.parse(localStorage.getItem("account"));
    setAccount(pAccount);
    setFirst(pAccount.user_first);
    setLast(pAccount.user_last);
    setCompanyCountry(pAccount.user_country);
    setCompanyState(pAccount.user_state);
    setCompanyCity(pAccount.user_city);
    setCompanyAddress(pAccount.user_address);
    setSubdomain(pAccount.user_subDomain);
    setPhone(pAccount.user_phone);
    setCompanyZip(pAccount.user_zip);
  }, []);

  const changeSubdomain = (event) => {
    const subd = event.target.value;
    const regex = new RegExp("^[a-zA-Z-]+$");
    if (regex.test(subd)) setSubdomain(subd);
    else if (subd == "") setSubdomain("");
    else setSubdomain(subdomain);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card style={{ padding: 20 }}>
        <CardHeader title="My Profile"></CardHeader>
        <MDBox component="form" role="form">
          <MDBox m={2} mb={5}>
            {localStorage.getItem("account") && (
              <>
                <MDTypography variant="h6" fontWeight="medium">
                  Your {process.env.REACT_APP_SITE_TITLE} API key:
                </MDTypography>
                <MDBox display="flex" alignItems="center">
                  <MDTypography variant="caption2" color="text">
                    {JSON.parse(localStorage.getItem("account")).user_apiKey}
                  </MDTypography>
                  <CopyToClipboard text={JSON.parse(localStorage.getItem("account")).user_apiKey}>
                    <IconButton sx={{ fontWeight: "bold" }} color="primary" aria-label="prompt">
                      <Tooltip id="button-report" title="Copy to clipboard">
                        <ContentCopyIcon />
                      </Tooltip>
                    </IconButton>
                  </CopyToClipboard>
                </MDBox>
              </>
            )}
            <Divider />

            <MDTypography variant="h6" fontWeight="medium" style={{ marginBottom: 20 }}>
              Your data:
            </MDTypography>
            <MDBox display="flex" flexDirection="row">
              <MDInput
                disabled={isLoading}
                type={"text"}
                label="Subdomain"
                placeholder="Only letters and dashes"
                value={subdomain}
                onChange={(e) => changeSubdomain(e)}
                required
                style={{ width: "500px" }}
              />
              <Tooltip
                id="descriptionHelp"
                title={
                  <MDTypography color={"white"}>
                    Choose a subdomain for pages published to {process.env.REACT_APP_MAIN_DOMAIN}{" "}
                    domain as {"<subdomain>." + process.env.REACT_APP_MAIN_DOMAIN}{" "}
                    {subdomain != "" &&
                      subdomain != null &&
                      "(" + subdomain + "." + process.env.REACT_APP_MAIN_DOMAIN}
                    {"). "}
                    The subdomain can only contain letters and dashes. Please note that if you
                    change the the subdomain later it will update ALL pages and funnels already
                    published to the new URL.
                  </MDTypography>
                }
              >
                <Icon style={{ marginTop: 8, marginLeft: 2 }}>info</Icon>
              </Tooltip>
            </MDBox>
            {account?.user_subDomain != "" && account?.user_subDomain != null && (
              <MDTypography variant="caption" color="warning">
                Changing your subdomain will change the URL of all existing published funnels and
                pages
              </MDTypography>
            )}
            {subdomainTaken && <MDBox color="red">Subdomain already taken</MDBox>}
            <MDBox color="red">
              {simpleValidator.current.message("Subdomain", subdomain, "required")}
            </MDBox>
          </MDBox>
          <MDBox m={2}>
            <MDInput
              disabled={isLoading}
              type={"text"}
              label="First Name"
              value={first}
              onChange={(e) => setFirst(e.target.value)}
              required
              style={{ width: "500px" }}
            />
            <MDBox color="red">{simpleValidator.current.message("First", first, "required")}</MDBox>
          </MDBox>
          <MDBox m={2}>
            <MDInput
              disabled={isLoading}
              type={"text"}
              label="Last Name"
              value={last}
              onChange={(e) => setLast(e.target.value)}
              required
              style={{ width: "500px" }}
            />
            <MDBox color="red">{simpleValidator.current.message("Last", last, "required")}</MDBox>
          </MDBox>
          <MDBox m={2}>
            <MDInput
              disabled={isLoading}
              type={"text"}
              label="Country"
              value={companyCountry}
              onChange={(e) => setCompanyCountry(e.target.value)}
              required
              style={{ width: "500px" }}
            />
            <MDBox color="red">
              {simpleValidator.current.message("Country", companyCountry, "required")}
            </MDBox>
          </MDBox>
          <MDBox m={2}>
            <MDInput
              disabled={isLoading}
              type={"text"}
              label="State"
              value={companyState}
              onChange={(e) => setCompanyState(e.target.value)}
              required
              style={{ width: "500px" }}
            />
            <MDBox color="red">
              {simpleValidator.current.message("State", companyState, "required")}
            </MDBox>
          </MDBox>
          <MDBox m={2}>
            <MDInput
              disabled={isLoading}
              type={"text"}
              label="City"
              value={companyCity}
              onChange={(e) => setCompanyCity(e.target.value)}
              required
              style={{ width: "500px" }}
            />
            <MDBox color="red">
              {simpleValidator.current.message("City", companyCity, "required")}
            </MDBox>
          </MDBox>

          <MDBox m={2}>
            <MDInput
              disabled={isLoading}
              type={"text"}
              label="Address"
              value={companyAddress}
              onChange={(e) => setCompanyAddress(e.target.value)}
              style={{ width: "500px" }}
            />
          </MDBox>
          <MDBox m={2}>
            <MDInput
              disabled={isLoading}
              type={"text"}
              label="Zip"
              value={companyZip}
              onChange={(e) => setCompanyZip(e.target.value)}
              style={{ width: "500px" }}
            />
          </MDBox>
          <MDBox m={2}>
            <MDInput
              disabled={isLoading}
              type={"text"}
              label="Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              style={{ width: "500px" }}
            />
          </MDBox>
          <MDBox m={2}>
            <MDButton
              disabled={isLoading}
              color="success"
              onClick={() => {
                verifySubdomain();
              }}
            >
              Save{isLoading && <CircularProgress></CircularProgress>}
            </MDButton>
          </MDBox>
        </MDBox>
      </Card>
      <MDSnackbar
        color="success"
        icon="success"
        title="Success"
        content="Your profile has been saved successfully"
        dateTime={""}
        open={openSaveSB}
        onClose={closeSaveSB}
        close={closeSaveSB}
        bgWhite
      />
    </DashboardLayout>
  );
}
