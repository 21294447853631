import React, { useState, useEffect } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardImg,
  CardBody,
  CardText,
  Spinner,
} from "reactstrap";
// import server from '../../../../../apis/server'; // Adjust the import path according to your project structure
import { CiImageOn } from "react-icons/ci";
import { MdDelete } from "react-icons/md";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

import { toast } from "react-toastify";
import client from "ApiClient";

const BackgroundImageUpload = ({ editor, backgroundImageUrl, setBgImage }) => {
  const [loading, setLoading] = useState(false);
  const [backgroundImagePreview, setBackgroundImagePreview] = useState(null);

  const [deleteModal, setDeleteModal] = useState(false);

  const toggleDeleteModal = (event) => {
    event.stopPropagation(); // Prevent the card onClick from triggering

    setDeleteModal(!deleteModal);
  };

  useEffect(() => {
    setBackgroundImagePreview(backgroundImageUrl || null);
  }, [backgroundImageUrl]);

  const uploadFile = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }

    // Generate background image preview immediately
    const imageUrl = URL.createObjectURL(file);
    setBackgroundImagePreview(imageUrl);

    setLoading(true);

    let fileSizeInBytes = file.size;
    let fileSizeInMB = fileSizeInBytes / (1024 * 1024); // Convert bytes to megabytes

    if (fileSizeInMB > 2) {
      toast("Please upload a file smaller than 2 MB.");
      setLoading(false);
      return;
    }

    const options = {
      method: "POST",
      url: `grapeai/uploadFavicon`,
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const formData = new FormData();
    formData.append("files", file);
    options.data = formData;
    client
      .request(options)
      .then((response) => {
        setBgImage(response);
        setLoading(false);
        toast("Background image uploaded successfully", "success");
        editor.runCommand(
          "set-background-image",
          {
            imageUrl: response,
          },
          () => {
            setBackgroundImagePreview(response); // Update the preview with the new image URL
            editor.refresh();
          }
        );
      })
      .catch((error) => {
        setLoading(false);
        toast(e.response, "error");
      });
  };

  const handleDeleteImage = async (event) => {
    toggleDeleteModal(event);

    setLoading(true);
    setBackgroundImagePreview(null);
    editor.runCommand("set-background-image", { imageUrl: "" }, () => {
      // Clears out the background styles when deleting the image
      const wrapper = editor.DomComponents.getWrapper();
      wrapper.setStyle({
        "background-image": "",
        "background-size": "",
        "background-repeat": "",
      });
      editor.refresh(); // Refresh to apply the changes
    });

    toast("Background image removed successfully", "success");
    // Reset the file input
    document.getElementById("background-image-upload").value = "";
    setLoading(false);
  };

  return (
    <div className={"styleDetails"}>
      <Form>
        <FormGroup>
          <Label for="BackgroundImage">Background Image</Label>
          <Input
            type={"file"}
            placeholder={"Upload an image"}
            onChange={uploadFile}
            style={{ display: "none" }}
            id="background-image-upload"
            accept="image/*"
          />
          <Card
            onClick={() => document.getElementById("background-image-upload").click()}
            style={{ cursor: "pointer", position: "relative" }}
          >
            {loading && (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 2,
                }}
              >
                <Spinner color="primary" />
              </div>
            )}
            {backgroundImagePreview ? (
              <>
                <CardImg
                  top
                  width="100%"
                  src={backgroundImagePreview}
                  alt="Background image preview"
                  style={{ opacity: loading ? 0.5 : 1 }}
                />
                <MdDelete
                  size={30}
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    cursor: "pointer",
                    color: "red",
                    zIndex: 3,
                  }}
                  onClick={toggleDeleteModal}
                />
              </>
            ) : (
              <CardBody>
                <CiImageOn size={50} />
                <CardText>Upload Background Image</CardText>
              </CardBody>
            )}
          </Card>
        </FormGroup>
      </Form>

      <Modal isOpen={deleteModal} toggle={toggleDeleteModal} centered>
        <ModalHeader toggle={toggleDeleteModal}>Confirm Deletion</ModalHeader>
        <ModalBody>Are you sure you want to delete the Background Image?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDeleteImage}>
            Clear
          </Button>{" "}
          <Button color="secondary" onClick={toggleDeleteModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default BackgroundImageUpload;
